import React from 'react'
import Navbar from '../components/Navbar'
import Pricing from '../components/Pricing'
import Newsletter from '../components/Newsletter'

const PricingWrap = () => {
	return (
		<div>
			<Navbar />
			<Pricing />
			<Newsletter />
		</div>
	)
}

export default PricingWrap
