import React from 'react'
import Navbar from './Navbar'

const Contact = () => {
	return (
		<div>
			<div className="w-full h-full  px-4 ">
				<div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-16">
					<h1 className="text-2xl text-[#0080fe]">Contact</h1>
				</div>
			</div>
		</div>
	)
}

export default Contact
