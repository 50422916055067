import React, { useState } from 'react'
import LeftImage from '../assets/holding-laptop.png'

const PopupForm = ({ onClose, onSubmit }) => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [email, setEmail] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')

	const handleSubmit = (e) => {
		e.preventDefault()

		// Handle form submission logic here
		onSubmit({
			firstName,
			lastName,
			companyName,
			email,
			phoneNumber,
		})
		onClose()
	}

	return (
		<div className="fixed inset-0 bg-sky-200 bg-opacity-50 flex justify-center items-center">
			<div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
				<div className=" mx-auto mt-2 grid md:grid-cols-2  gap-4">
					<div className="border-r  bg-sky-200 rounded-lg flex justify-center items-center">
						<img
							src={LeftImage}
							alt="Left"
							className="max-w-full h-auto mr-4"
						/>
					</div>
					<div>
						<h2 className="text-2xl font-semibold mb-4 ">
							Contact Information
						</h2>
						<form onSubmit={handleSubmit}>
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									htmlFor="firstName"
								>
									First Name
								</label>
								<input
									type="firstName"
									id="firstName"
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									value={firstName}
									onChange={(e) =>
										setFirstName(e.target.value)
									}
									required
								/>
							</div>
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									htmlFor="lastName"
								>
									Last Name
								</label>
								<input
									type="lastName"
									id="lastName"
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									value={lastName}
									onChange={(e) =>
										setLastName(e.target.value)
									}
									required
								/>
							</div>
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									htmlFor="companyName"
								>
									Company
								</label>
								<input
									type="companyName"
									id="companyName"
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									value={companyName}
									onChange={(e) =>
										setCompanyName(e.target.value)
									}
									required
								/>
							</div>
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									htmlFor="email"
								>
									Email
								</label>
								<input
									type="email"
									id="email"
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									value={email}
									onChange={(e) =>
										setEmail(e.target.value)
									}
									required
								/>
							</div>
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									htmlFor="phoneNumber"
								>
									Phone Number
								</label>
								<input
									type="phoneNumber"
									id="phoneNumber"
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
									value={phoneNumber}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
									required
								/>
							</div>
							<div className="flex justify-end">
								<button
									type="button"
									className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
									onClick={onClose}
								>
									Cancel
								</button>
								<button
									type="submit"
									className="bg-blue-500 text-white px-4 py-2 rounded"
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PopupForm
