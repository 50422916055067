import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Solution from './wrappers/Solution'
import Features from './wrappers/Features'
import Pricing from './wrappers/Pricing'
import Learn from './wrappers/Learn'
import About from './wrappers/About'
import Contact from './wrappers/Contact'
import Demo from './wrappers/Demo'
import PrivacyPolicy from './wrappers/PrivacyPolicy'
import LocationsWrap from './wrappers/LocationsWrap'
import ProjectsWrap from './wrappers/ProjectsWrap'
import PartnersWrap from './wrappers/PartnersWrap'
import ChatsWrap from './wrappers/ChatsWrap'
import FoldersWrap from './wrappers/FoldersWrap'
import FutureWrap from './wrappers/FutureWrap'

const router = createBrowserRouter([
	{ path: '/', element: <App /> },
	{ path: '/solution', element: <Solution /> },
	{ path: '/features', element: <Features /> },
	{ path: '/pricing', element: <Pricing /> },
	{ path: '/learn', element: <Learn /> },
	{ path: '/about', element: <About /> },
	{ path: '/contact', element: <Contact /> },
	{ path: '/demo', element: <Demo /> },
	{ path: '/locations', element: <LocationsWrap /> },
	{ path: '/projects', element: <ProjectsWrap /> },
	{ path: '/partners', element: <PartnersWrap /> },
	{ path: '/chats', element: <ChatsWrap /> },
	{ path: '/folders', element: <FoldersWrap /> },
	{ path: '/future', element: <FutureWrap /> },
	{ path: '/privacypolicy', element: <PrivacyPolicy /> },
])

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)
