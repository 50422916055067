import React from 'react'
import FeatureIcon from '../assets/icon-chats.png'
import PrimaryImage from '../assets/chats.png'

const FirstPanel = () => {
	return (
		<div className="w-full  py-2 px-4">
			<div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
				<div className="flex flex-col justify-center w-[340px] md:ml-2">
					<h1 className="md:text-4xl sm:text-3xl text-2xl font-bold p-2 underline underline-offset-4 text-[#0080fe]">
						Communication
					</h1>
					<div className="pl-6">
						<img
							className="w-[100px]  m-4"
							src={FeatureIcon}
							alt="/"
						/>
					</div>

					<p className="md:text-3xl sm:text-2xl text-xl text-[#0080fe] p-2">
						Team Communication:
					</p>
					<div className="pl-6">
						<ul className="list-disc  text-2xl p-2">
							<li>
								Communicate with Team Members via
								Realtime Chat and Email
							</li>
						</ul>
					</div>
				</div>
				<div className="w-[760px]">
					<img
						className=" mx-auto my-24"
						src={PrimaryImage}
						alt="/"
					/>
				</div>
			</div>
		</div>
	)
}

export default FirstPanel
