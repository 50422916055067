import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Locations from './Locations'
import Projects from './Projects'
import Collaboration from './Collaboration'
import Communication from './Communication'

const Features = () => {
	const location = useLocation()

	useEffect(() => {
		if (location.hash === '#locations') {
			const element = document.getElementById('locations')
			if (element) {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest',
				})
			}
		}
		if (location.hash === '#projects') {
			const element = document.getElementById('projects')
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}
		}
		if (location.hash === '#collaboration') {
			const element = document.getElementById('collaboration')
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}
		}
		if (location.hash === '#communication') {
			const element = document.getElementById('communication')
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [location])

	return (
		<div>
			<div id="locations">
				<Locations />
			</div>
			<div id="projects">
				<Projects />
			</div>
			<div id="collaboration">
				<Collaboration />
			</div>
			<div id="communication">
				<Communication />
			</div>
		</div>
	)
}

export default Features
