import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import LeftImage from '../assets/FullLogo_Transparent_NoBuffer.png'

const Navbar = () => {
	const [nav, setNav] = useState(true)

	const handleNav = () => {
		setNav(!nav)
	}
	return (
		<div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-black">
			<div className="flex flex-col justify-center md:ml-16">
				<Link to="/" className="hover:scale-125 duration-100">
					<img
						className="w-[128px] mx-auto my-4"
						src={LeftImage}
						alt="/"
					/>
				</Link>
			</div>

			<div className="hidden md:flex text-xl">
				<Link
					to="/"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Home
				</Link>
				<Link
					to="/solution"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Solution
				</Link>
				<Link
					to="/features"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Features
				</Link>
				<Link
					to="/about"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					About
				</Link>
				<Link
					to="/pricing"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Pricing
				</Link>
				<Link
					to="/learn"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Learn
				</Link>
				<Link
					to="/contact"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Contact
				</Link>
				<Link
					to="/demo"
					className="p-4 font-semibold hover:text-[#0088fe] hover:scale-125 duration-100"
				>
					Demo
				</Link>
			</div>
			<div onClick={handleNav} className="block md:hidden">
				{!nav ? (
					<AiOutlineClose size={20} />
				) : (
					<AiOutlineMenu size={20} />
				)}
			</div>
			<div
				className={
					!nav
						? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500'
						: 'fixed left-[-100%]'
				}
			>
				<h1 className="w-full text-2xl  text-[#0080fe] m-4">
					MDTeams
				</h1>
				<ul className="uppercase p-2">
					<div className="p-4 border-b border-gray-600">
						<Link to="/">Home</Link>
					</div>
					<div className="p-4 border-b border-gray-600">
						<Link to="/solution">Solution</Link>
					</div>
					<div className="p-4 border-b border-gray-600">
						<Link to="/features">Features</Link>
					</div>
					<div className="p-4 border-b border-gray-600">
						<Link to="/about">About</Link>
					</div>
					<div className="p-4 border-b border-gray-600">
						<Link to="/pricing">Pricing</Link>
					</div>
					<div className="p-4 border-b border-gray-600">
						<Link to="/learn">Learn</Link>
					</div>
					<div className="p-4 border-b border-gray-600">
						<Link to="/contact">Contact</Link>
					</div>
					<div className="p-4">
						<Link to="/demo">Demo</Link>
					</div>
				</ul>
			</div>
		</div>
	)
}

export default Navbar
