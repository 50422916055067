import React from 'react'
import Navbar from '../components/Navbar'
import Features from '../components/Features'
import Newsletter from '../components/Newsletter'

const FeaturesWrap = () => {
	return (
		<div>
			<Navbar />
			<Features />
			<Newsletter />
		</div>
	)
}

export default FeaturesWrap
