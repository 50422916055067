import React from 'react'
import Navbar from '../components/Navbar'
import Demo from '../components/Demo'
import Newsletter from '../components/Newsletter'

const DemoWrap = () => {
	return (
		<div>
			<Navbar />
			<Demo />
			<Newsletter />
		</div>
	)
}

export default DemoWrap
