import React from 'react'
import Navbar from '../components/Navbar'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Newsletter from '../components/Newsletter'

const PrivacyPolicyWrap = () => {
	return (
		<div>
			<Navbar />
			<PrivacyPolicy />
			<Newsletter />
		</div>
	)
}

export default PrivacyPolicyWrap
