import React from 'react'
import PrimaryImage from '../assets/Blend_1.png'
import CTAButton from './CTAButton'

const Solution = () => {
	return (
		<div className="w-full py-2">
			<div>
				<div className="max-w-[1200px] mt-[2px] w-full mx-auto text-center flex flex-col justify-center">
					<h1 className="md:text-4xl sm:text-3xl text-2xl p-2 text-[red]">
						Coming Soon!
					</h1>
					<div className="py-1 font-semibold text-lg">
						<p className="text-[#052852]  ">
							The MYDealTeams beta launch is coming soon,
							and we're looking for industry professionals
							who would like to help test this exciting new
							platform.
						</p>
					</div>
					<div className="py-1 font-semibold text-lg">
						<p className="text-[#052852]  ">
							If you're looking to take your location and
							deal management process to the next level,
							you'll love what you see here!
						</p>
					</div>
				</div>
				<div>
					<img
						className="max-w-[900px] mx-auto"
						src={PrimaryImage}
						alt="/"
					/>
				</div>
				<CTAButton actionPhrase="Join Our Private Beta" />
			</div>
		</div>
	)
}

export default Solution
