import React from 'react'
import CardPricing from './CardPricing'
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'

const Pricing = () => {
	return (
		<div>
			<div className="w-full h-full  px-4 ">
				<div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-16">
					<CardPricing
						cardImage={Single}
						cardHeader="Community User"
						cardPrice="Free"
						cardList={[
							'Join Existing Projects',
							'Add and modify Project Tasks',
							'Add Notes and Initiate Chats',
							'Add Images',
							'Partner with existing Users',
							'Join Existing Portfolios',
						]}
						cardButton="Start Demo"
					/>
					<CardPricing
						cardImage={Double}
						cardHeader="Pro User"
						cardPrice="$69"
						cardList={[
							'Create and Load New Locations',
							'Create Projects',
							'Add and modify Project Tasks',
							'Add Notes and Initiate Chats',
							'Add Images',
							'Upload Documents',
							'Build Task Templates',
							'Build Site Detail Templates',
							'Partner with existing Users',
							'Invite New Users',
							'Build Portfolios',
						]}
						cardButton="Start Demo"
					/>
					<CardPricing
						cardImage={Triple}
						cardHeader="Administrator"
						cardPrice="$89"
						cardList={[
							'Join Existing Projects',
							'Add and modify Project Tasks',
							'Add Notes and Initiate Chats',
							'Add Images',
							'Partner with existing Users',
							'Join Existing Portfolios',
						]}
						cardButton="Start Demo"
					/>
				</div>
			</div>
		</div>
	)
}

export default Pricing
