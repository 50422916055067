import React from 'react'
import Navbar from '../components/Navbar'
import Locations from '../components/Locations'
import Newsletter from '../components/Newsletter'

const LocationsWrap = () => {
	return (
		<div>
			<Navbar />
			<Locations />
			<Newsletter />
		</div>
	)
}

export default LocationsWrap
