import React from 'react'
import PrimaryImage from '../assets/happy.png'
import CTAButton from './CTAButton'

const FirstPanel = () => {
	return (
		<div className="max-w-[1240px] mx-auto grid md:grid-cols-2 mb-32">
			<div>
				<img
					className="w-[600px] mx-auto my-4"
					src={PrimaryImage}
					alt="/"
				/>
			</div>
			<div className="w-[700px] flex flex-col justify-center md:ml-16">
				{/* <p className="text-[#0080fe] font-bold p-2">
						Coming Soon . . .
					</p> */}
				<h1 className="md:text-4xl sm:text-3xl text-2xl p-2 text-[red]">
					Your Gateway to{' '}
					<span className=" text-[#0080fe]">Real Estate </span>{' '}
					Excellence.
				</h1>
				<div className=" mt-4 md:text-2xl sm:text-xl text-lg text-[#052852] pl-6">
					<ul className="list-disc p-2">
						<li>
							Interact with your teams and partners with
							Chat and Email integration.
						</li>
						<li>
							Share notes and observations, and upload and
							share documents, images, and videos from
							multiple sources.
						</li>
						<li>
							Manage all your real estate projects with a
							robust workflow and task management system.
						</li>
					</ul>
				</div>
				<h1 className="text-center md:text-2xl sm:text-xl text-lg p-2 text-[#25A4FC]">
					Affordable, Streamlined, Comprehensive.
				</h1>
				<CTAButton actionPhrase="Join Our Private Beta" />
			</div>
		</div>
	)
}

export default FirstPanel
