import React, { useState } from 'react'
import PopupForm from './PopupForm'
import PopupThankYou from './PopupThankYou'
import {
	emailInviteNewUser,
	emailInviteNewUsercc,
} from '../functions/emailOptions'

const CTAButton = ({ actionPhrase }) => {
	const [showPopup, setShowPopup] = useState(false)
	const [showThankYouPopup, setShowThankYouPopup] = useState(false)

	const handleButtonClick = () => {
		setShowPopup(true)
	}

	const handleClosePopup = () => {
		setShowPopup(false)
	}

	const handleCloseThankYouPopup = () => {
		setShowThankYouPopup(false)
	}

	const handleFormSubmit = (contactInfo) => {
		const { firstName, lastName, companyName, email, phoneNumber } =
			contactInfo

		const fromUser = {
			name: 'MYDealTeams',
			email: 'mikeoc@mydealteams.com',
		}

		const toUser = {
			firstName: firstName,
			lastName: lastName,
			toName: `${firstName} ${lastName}`,
			companyName: companyName,
			email: email,
			phoneNumber: phoneNumber,
		}

		// Send email to the provided email address
		emailInviteNewUser(fromUser, toUser)

		// Send email to info@mydealteams.com
		emailInviteNewUsercc(fromUser, toUser)
		setShowThankYouPopup(true)
	}

	return (
		<div>
			<div className="flex justify-center">
				<button
					className="bg-[#0080fe] w-[220px] text-white  rounded-md font-medium my-6 mx-auto md:mx-8 py-3 hover:scale-110 duration-150"
					onClick={handleButtonClick}
				>
					<span>{actionPhrase}</span>
				</button>
			</div>
			{showPopup && (
				<PopupForm
					onClose={handleClosePopup}
					onSubmit={handleFormSubmit}
				/>
			)}
			{showThankYouPopup && (
				<PopupThankYou onClose={handleCloseThankYouPopup} />
			)}
		</div>
	)
}

export default CTAButton
