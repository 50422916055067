import React from 'react'

const CardPricing = ({
	cardImage,
	cardHeader,
	cardPrice,
	cardList,
	cardButton,
}) => {
	return (
		<div className="w-full border shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-100">
			{/* <img
				className="w-20 mx-auto mt-[-3rem] "
				src={cardImage}
				alt=""
			/> */}
			<h2 className="text-2xl font-bold text-center py-2">
				{cardHeader}
			</h2>
			<p className="text-center text-4xl font-bold py-2">
				{cardPrice}
			</p>
			<div className="font-medium flex-grow flex items-start justify-center">
				<ul className="list-disc">
					{cardList.map((listItem, index) => (
						<li key={index}>{listItem}</li>
					))}
				</ul>
			</div>
			<button className="bg-[#0080fe] w-[280px] text-white rounded-md font-medium my-3 mx-auto md:mx-8 py-3 mt-6">
				{cardButton}
			</button>
		</div>
	)
}

export default CardPricing
