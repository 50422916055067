import React from 'react'
import { Link } from 'react-router-dom'
import CTAButton from './CTAButton'

const Newsletter = () => {
	return (
		<div className="w-full pt-1 pb-16 bg-[#074f9b] text-white">
			<div className="mt-16 max-w-[1400px] mx-auto grid lg:grid-cols-3">
				<div className="lg:col-span-2 pr-4 my-6">
					<h1 className="md:text-4xl sm:text-3xl text-2xl ">
						We'd love to hear from you.!
					</h1>
					{/* <p>We'd love to hear from you.</p> */}
				</div>
				<div className="ml-4 ">
					<div className="flex flex-col md:flex-row items-center justify-between">
						<input
							type="email"
							placeholder="Enter your email"
							className="p-3 flex  rounded-md w-[280px]  "
						/>
						{/* <button className="bg-[#0080fe] text-white  rounded-md font-medium w-[280px]  my-6 p-3 mr-4">
							Notify Me!
						</button> */}
						<CTAButton actionPhrase="Contact Us" />
					</div>
					<p>
						We care about the protection and privacy of your
						data. Read our{' '}
						<span className="mb-16 text-[#0080fe]">
							<Link
								className="underline underline-offset-4"
								to="/privacypolicy"
							>
								Privacy Policy
							</Link>
						</span>{' '}
					</p>
				</div>
			</div>
		</div>
	)
}

export default Newsletter
