import React from 'react'
import { Link } from 'react-router-dom'

const CardFeatures = ({ cardBanner, cardImage, cardLink }) => {
	return (
		<div className="w-full border shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-100">
			<Link to={cardLink}>
				<img
					className="w-48 mx-auto  pb-4"
					src={cardImage}
					alt=""
				/>
				<h2 className="text-[#052852] text-2xl font-semibold text-center py-3">
					{cardBanner}
				</h2>
			</Link>
		</div>
	)
}

export default CardFeatures
