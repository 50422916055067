import React, { useState } from 'react'

const PopupForm = ({ onClose }) => {
	const handleSubmit = (e) => {
		e.preventDefault()

		// Handle form submission logic here
		onClose()
	}

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
			<div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
				<h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
				<form onSubmit={handleSubmit}>
					<div className="flex justify-end">
						{/* <button
							type="button"
							className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
							onClick={onClose}
						>
							Cancel
						</button> */}
						<button
							type="submit"
							className="bg-blue-500 text-white px-4 py-2 rounded"
							onClick={onClose}
						>
							Ok
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PopupForm
