import React from 'react'
import Navbar from '../components/Navbar'
import Projects from '../components/Projects'
import Newsletter from '../components/Newsletter'

const ProjectsWrap = () => {
	return (
		<div>
			<Navbar />
			<Projects />
			<Newsletter />
		</div>
	)
}

export default ProjectsWrap
