import React from 'react'
import PrimaryImage from '../assets/man-at-sign.png'

const About = () => {
	return (
		<div className="w-full h-full  py-2">
			<div className="">
				<h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl p-4 text-[red] ">
					Welcome to MYDealTeams
				</h1>
				<div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-4">
					<div className="flex  w-[600px] justify-center">
						<img
							className="flex "
							src={PrimaryImage}
							alt="/"
						/>
					</div>
					<div className="justify-start">
						<p className="w-full text-2xl ml-8 mb-2 font-semibold underline underline-offset-4 max-w-[800px]">
							We Understand you.
						</p>
						<p className="w-full text-2xl ml-8 mb-8 max-w-[800px]">
							At MYDealTeams, we understand the
							complexities of retail real estate because
							we've been in your shoes. Founded on nearly
							two decades of hands-on experience supporting
							real estate deals, we’ve made it our mission
							to simplify and streamline the deal process
							for professionals like you.
						</p>
						<p className="w-full text-2xl ml-8 mb-2 font-semibold underline underline-offset-4 max-w-[800px]">
							The Vision Behind MYDealTeams{' '}
						</p>
						<p className="w-full text-2xl ml-8 mb-8 max-w-[800px]">
							We’ve sat in your seat, navigating the
							challenges of managing locations, tasks, and
							communications across deal teams. We’ve
							experienced the frustrations of juggling
							multiple tools, scattered information, and
							siloed team collaboration. That’s why we
							created MYDealTeams — to be the tool that
							transforms the way your team works together.
						</p>
					</div>
				</div>
			</div>

			<div className="flex py-20">
				<div className="max-w-[1240px] mx-auto ">
					<p className="w-full text-2xl ml-8 font-semibold underline underline-offset-4 max-w-[1200px]">
						Here’s what we’ve know about your business and the
						challenges you face:{' '}
					</p>
					<div className="w-full text-2xl ml-8 max-w-[800px]">
						<ul className="list-disc p-2 ml-20">
							<li>
								<span className="font-semibold">
									Comprehensive Location Management:{' '}
								</span>
								You need a solution that lets you
								oversee all your locations in one place.
							</li>
							<li>
								<span className="font-semibold">
									Efficient Project & Task Tracking:{' '}
								</span>
								Whether it’s new leases, remodels, or
								new store openings, managing projects
								and tasks effectively is critical.
							</li>
							<li>
								<span className="font-semibold">
									Seamless Team Communication:{' '}
								</span>
								Keeping everyone—from internal
								stakeholders to external partners—on the
								same page is essential, and you need a
								system that retains those conversations
								for future reference.
							</li>
							<li>
								<span className="font-semibold">
									Centralized Access for Deal Teams:{' '}
								</span>
								All team members should have access to
								documents, projects, task assignments,
								and communications—without jumping
								between systems.{' '}
							</li>
							<li>
								<span className="font-semibold">
									Integrated Tools & Applications:{' '}
								</span>
								Your tools should work seamlessly
								together, not create more work for your
								team.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About
