import React from 'react'
import PrimaryImage from '../assets/button-group.png'
import SecondaryImage from '../assets/holding-laptop.png'
import IconLocation from '../assets/icon-location.png'
import IconProjects from '../assets/icon-projects.png'
import IconPartners from '../assets/icon-partners.png'
import IconChats from '../assets/icon-chats.png'
import IconFolder from '../assets/icon-folder.png'
import IconFuture from '../assets/icon-future.png'

const Solution = () => {
	return (
		<div className="w-full  py-2">
			<div className="h-screen">
				{/* <h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl p-4 text-[#0080fe]"> */}
				<h1 className="w-full mx-auto text-center md:text-4xl sm:text-3xl text-2xl p-4 text-[red] ">
					We Know What You Want!
				</h1>
				<div className="max-w-[1240px] mx-auto mt-6 grid md:grid-cols-2">
					<div className="max-w-[900px] mt-[2px] w-full mx-auto flex flex-col justify-center row-auto ">
						<ul className="justify-items-start md:text-3xl sm:text-2xl text-sky-600 text-xl font-semibold ">
							<li className="flex max-w-[500px] items-center mb-6">
								<img
									className="mx-auto h-[54px] w-[54px] mr-2 "
									src={IconLocation}
									alt="/"
								/>{' '}
								All my locations in One Place
							</li>
							<li className="flex max-w-[500px] items-center mb-6">
								<img
									className="mx-auto h-[54px] w-[54px] mr-2 "
									src={IconProjects}
									alt="/"
								/>{' '}
								Create Projects and assign tasks
							</li>
							<li className="flex max-w-[500px] items-center mb-6">
								<img
									className="mx-auto h-[54px] w-[54px] mr-2 "
									src={IconPartners}
									alt="/"
								/>{' '}
								Invite deal team members to share the
								work
							</li>
							<li className="flex max-w-[500px] items-center mb-6">
								<img
									className="mx-auto h-[54px] w-[54px] mr-2 "
									src={IconChats}
									alt="/"
								/>{' '}
								Communicate with your team in real time
							</li>
							<li className="flex max-w-[500px] items-center mb-6">
								<img
									className="mx-auto h-[54px] w-[54px] mr-2 "
									src={IconFolder}
									alt="/"
								/>{' '}
								Share and retain all your notes,
								documents and images
							</li>
							<li className="flex max-w-[500px] items-center mb-6">
								<img
									className="mx-auto h-[54px] w-[54px] mr-2 "
									src={IconFuture}
									alt="/"
								/>{' '}
								Future support for third party
								integrations
							</li>
						</ul>
					</div>
					<div className="max-w-[540px] mt-[12px] w-full mx-auto text-center flex flex-col justify-center">
						<img
							className=" mx-auto "
							src={SecondaryImage}
							alt="/"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Solution
