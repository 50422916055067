import React from 'react'
import Navbar from '../components/Navbar'
import Solution from '../components/Solution'
import SolutionIcons from '../components/SolutionIcons'
import Newsletter from '../components/Newsletter'

const SolutionWrap = () => {
	return (
		<div>
			<Navbar />
			<SolutionIcons />
			<Solution />
			<Newsletter />
		</div>
	)
}

export default SolutionWrap
