import React, { useEffect, useState } from 'react'
import TypingEffect from 'react-typed.ts'
import LeftImage from '../assets/FullLogo_Transparent_NoBuffer.png'
import RightImage from '../assets/tool-home.png'
import CTAButton from './CTAButton'

const Home = () => {
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		setAnimate(true)
	}, [])

	return (
		<div className="w-full h-screen py-2 px-4">
			<div className=" mx-auto mt-2 grid md:grid-cols-2">
				<div
					className={`flex flex-col justify-center md:ml-16 ${
						animate ? 'slide-in-left' : ''
					}`}
				>
					<img
						className="w-[700px] mx-auto my-4 "
						src={LeftImage}
						alt="/"
					/>
					<div className="flex items-center justify-center mt-6">
						<p className=" text-[#25A4FC] md:text-4xl sm:text-3xl text-xl font-semibold ">
							Retail Location Management
						</p>
					</div>
				</div>

				<div
					className={`flex flex-col justify-center md:ml-16 ${
						animate ? 'slide-in-right' : ''
					}`}
				>
					<img
						className="w-[700px] mx-auto my-4"
						src={RightImage}
						alt="/"
					/>
				</div>
			</div>
			<div
				className={`max-w-[800px] mt-[12px] w-full mx-auto text-center flex flex-col justify-center ${
					animate ? 'slide-in-bottom' : ''
				}`}
			>
				<h1 className="text-[red] md:text-6xl sm:text-5xl text-3xl font-semibold md:py-2">
					From Chaos to{' '}
					<span className="text-[#0080fe]">Clarity</span>
				</h1>
				<div className="flex items-center justify-center text-[#074f9b]">
					<p className=" md:text-3xl sm:text-2xl text-xl font-semibold py-1">
						Your
					</p>
					<TypingEffect
						className="md:text-3xl sm:text-2xl text-xl font-semibold md:pl-4 pl-2"
						strings={[
							'Locations',
							'Teams',
							'Tools',
							'Communications',
						]}
						typedSpeed={40}
						backSpeed={50}
						loop
						cursorChar=" "
					/>
				</div>
			</div>
			<CTAButton actionPhrase="Join Our Private Beta" />
		</div>
	)
}

export default Home
