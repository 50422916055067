import React from 'react'
import './styles/global.css' // Import the global CSS file
import Navbar from './components/Navbar'
import Home from './components/Home'
import FirstPanel from './components/FirstPanel'
import Newsletter from './components/Newsletter'
import Solution from './components/Solution'
import FeaturesPanel from './components/FeaturesPanel'

function App() {
	return (
		<div>
			<Navbar />
			<Home />
			<FirstPanel />
			<FeaturesPanel />
			<Solution />
			<Newsletter />
		</div>
	)
}

export default App
