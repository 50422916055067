import React from 'react'
import Navbar from '../components/Navbar'
import Contact from '../components/Contact'
import Newsletter from '../components/Newsletter'

const ContactWrap = () => {
	return (
		<div>
			<Navbar />
			<Contact />
			<Newsletter />
		</div>
	)
}

export default ContactWrap
