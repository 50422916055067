import React from 'react'
import CardFeatures from './CardFeatures'
import IconLocation from '../assets/icon-location.png'
import IconProjects from '../assets/icon-projects.png'
import IconPartners from '../assets/icon-partners.png'
import IconChats from '../assets/icon-chats.png'
import IconFolder from '../assets/icon-folder.png'
import IconFuture from '../assets/icon-future.png'

const FeaturesPanel = () => {
	return (
		<div className="w-full px-4 mb-16">
			<div className="mb-6">
				<div className="max-w-[900px] mx-auto text-center flex flex-col justify-center">
					<h1 className="md:text-4xl sm:text-3xl text-2xl p-2 text-[red]">
						Streamlining Deal Teams for{' '}
						<span className="font-bold text-[#0080fe]">
							Real Estate{' '}
						</span>{' '}
						Excellence.
					</h1>
					<p className="text-gray-800 text-lg font-semibold p-2">
						A winning combination of collaboration and site
						visibility. Bring your partners, teams, and
						resources for deal execution together in one fun
						new space.
					</p>
				</div>
			</div>

			<div>
				<div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-16">
					<CardFeatures
						cardImage={IconLocation}
						cardBanner="Locations"
						cardLink="/features#locations"
					/>
					<CardFeatures
						cardImage={IconProjects}
						cardBanner="Projects and Tasks"
						cardLink="/features#projects"
					/>
					<CardFeatures
						cardImage={IconPartners}
						cardBanner="Partners"
						cardLink="/features#collaboration"
					/>
				</div>
			</div>
			<div className="w-full h-full  px-4 ">
				<div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-16">
					<CardFeatures
						cardImage={IconChats}
						cardBanner="Email and Chats"
						cardLink="/features#ccommunication"
					/>
					<CardFeatures
						cardImage={IconFolder}
						cardBanner="File Management"
						cardLink="/folders"
					/>
					<CardFeatures
						cardImage={IconFuture}
						cardBanner="Integrations"
						cardLink="/future"
					/>
				</div>
			</div>
		</div>
	)
}

export default FeaturesPanel
