import React from 'react'
import Navbar from '../components/Navbar'
import Partners from '../components/Partners'
import Newsletter from '../components/Newsletter'

const PartnersWrap = () => {
	return (
		<div>
			<Navbar />
			<Partners />
			<Newsletter />
		</div>
	)
}

export default PartnersWrap
